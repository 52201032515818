<template>
  <b-container>
    <app-page-header title="Verification Requests"/>
    <b-table :items="users" :fields="fields" :busy="$apollo.queries.users.loading" show-empty>
      <template #table-busy>
        <b-spinner />
      </template>
      <template #empty>
        No verification requests pending.
      </template>
      <template #cell(actions)="data">
        <app-verify-user-button :user="data.item" @refresh="refresh" />
      </template>
    </b-table>
  </b-container>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import AppVerifyUserButton from '@/components/users/VerifyUserButton.vue';
import ListVerificationTasks from '@/gql/verification/ListVerificationTasks.gql';

export default {
  components: {
    AppPageHeader,
    AppVerifyUserButton,
  },
  apollo: {
    users: {
      query: ListVerificationTasks,
    },
  },
  data() {
    return {
      fields: [
        'username',
        'firstname',
        'lastname',
        'displayname',
        'requestedBadgeType',
        'badgeType',
        'actions',
      ],
      user: null,
    };
  },
  methods: {
    refresh() {
      console.log('Refresh');
      this.$apollo.queries.users.refetch();
    },
  },
};
</script>
