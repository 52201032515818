<template>
  <div>
    <b-card header="User Limits" class="mb-3">
      <table class="table table-sm table-borderless">
        <tr>
          <td>Message Characters</td>
          <td class="text-right">
            {{user.userParams.messageCharacters}}
          </td>
        </tr>
        <tr>
          <td>Forum Video Duration</td>
          <td class="text-right">
            {{user.userParams.quickVideoDuration}}
          </td>
        </tr>
        <tr>
          <td>Gallery Video Duration</td>
          <td class="text-right">
            {{user.userParams.galleryVideoDuration}}
          </td>
        </tr>
        <tr>
          <td>Post Characters</td>
          <td class="text-right">
            {{user.userParams.postCharacters}}
          </td>
        </tr>
      </table>
      <b-button v-if="fullAccess" variant="outline-primary" size="small" block @click="showLimitsModal()">Edit Limits</b-button>
    </b-card>
    <b-modal ref="LimitsModal" @ok="saveLimits()">
      <!-- // quickVideoDuration, galleryVideoDuration, messageCharacters, postCharacters -->
      <b-form-group label="Quick Video Duration">
        <b-form-input v-model="draftParams.quickVideoDuration"></b-form-input>
      </b-form-group>
      <b-form-group label="Gallery Video Duration">
        <b-form-input v-model="draftParams.galleryVideoDuration"></b-form-input>
      </b-form-group>
      <b-form-group label="Message Characters">
        <b-form-input v-model="draftParams.messageCharacters"></b-form-input>
      </b-form-group>
      <b-form-group label="Post Characters">
        <b-form-input type="number" v-model="draftParams.postCharacters"></b-form-input>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import $auth from '@/services/auth';
import UpdateUserParams from '@/gql/users/UpdateUserParams.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      draftParams: {},
    };
  },
  computed: {
    fullAccess() {
      return $auth.accessType() === 'full';
    },
  },
  methods: {
    showLimitsModal() {
      this.draftParams = this.user.userParams ? { ...this.user.userParams } : {};
      this.$refs.LimitsModal.show();
    },
    async saveLimits() {
      await this.$apollo.mutate({
        mutation: UpdateUserParams,
        variables: {
          id: this.user.id,
          userParams: this.draftParams,
        },
      });
      this.$emit('refresh');
    },
  },
};
</script>
