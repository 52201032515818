<template>
  <b-card header="Sessions" class="mb-3" v-if="sessions">
    <div>{{sessions.length}} sessions</div>
    <div v-for="session in sessions" :key="session.createdAt" class="session-item">
      <div>{{session.createdAt | moment('from')}}</div>
      <div>App Version: {{session.appVersion}}</div>
      <div>OS: {{session.os}}</div>
      <div>Device: {{session.device}}</div>
      <div>Browser: {{getBrowserName(session.browser)}}</div>
    </div>
  </b-card>
</template>
<script>
import ListUserSessions from '@/gql/users/ListUserSessions.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    sessions: {
      query: ListUserSessions,
      variables() {
        return {
          userId: this.user.id,
        };
      },
    },
  },
  methods: {
    getBrowserName(info) {
      let name = 'Unknown';
      if (info.ie) {
        name = 'Internet Explorer';
      } else if (info.opera) {
        name = 'Opera';
      } else if (info.chrome) {
        name = 'Chrome';
      } else if (info.safari) {
        name = 'Safari';
      } else if (info.android) {
        name = 'Android';
      } else if (info.firefox) {
        name = 'Firefox';
      } else if (info.mobile_safari) {
        name = 'Mobile Safari';
      }
      return `${name} (${info.version})`;
    },
  },
};
</script>
<style scoped>
.session-item
{
  margin-bottom: 10px;
}
</style>
