<template>
  <div>
    <b-button @click="showModal()" size="small" variant="outline-primary" block>Verify</b-button>
    <b-modal ref="VerifyModal" @ok="saveProfile()" hide-footer size="xl" :title="'Verify ' + user.username">
      <b-row>
        <b-col>
          <a :href="profileLink" target="_blank">{{user.username}} ({{user.firstname}} {{user.lastname}})</a>
          <span> requested {{user.requestedBadgeType || 'user'}} status.</span>
          <p>Profile Picture:</p>
          <img v-if="profilePicture" :src="profilePicture" class="verification-image">
          <div>
            <p>Identity Verification:</p>
            <img v-if="user.verificationUrl" :src="verificationImage" class="verification-image">
            <div v-if="!user.verificationUrl">
              No documentation provided
            </div>
          </div>
        </b-col>
        <b-col>
          <h4>Approve:</h4>
          <b-button class="block-button" variant="primary" @click="setBadgeType('user', 'accepted')">Approve as Verified User</b-button>
          <b-button class="block-button" variant="primary" @click="setBadgeType('influencer', 'accepted')">Approve as Influencer</b-button>
          <b-button class="block-button" variant="primary" @click="setBadgeType('founder', 'accepted')">Approve as Founder</b-button>
          <hr>
          <h4>Reject:</h4>
          <!-- <b-button class="block-button" variant="danger" @click="setBadgeType('none', 'false')">Set as Fake</b-button> -->
          <b-form-group label="Rejection Reason">
            <b-select v-model="rejectionReason">
              <b-select-option value="no-user-or-id">No Picture of User or ID</b-select-option>
              <b-select-option value="no-user">No Picture of User</b-select-option>
              <b-select-option value="no-id">No Picture of ID</b-select-option>
              <b-select-option value="unclear-id">Unclear ID</b-select-option>
              <b-select-option value="name-mismatch">Name doesn't match</b-select-option>
              <b-select-option value="influencer-need-more-info">Influencer Need More Info</b-select-option>
            </b-select>
          </b-form-group>
          <b-button class="block-button" variant="danger" @click="setBadgeType('none', 'none')" :disabled="!rejectionReason">Reject</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import SetBadgeType from '@/gql/verification/SetBadgeType.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rejectionReason: null,
    };
  },
  computed: {
    profileLink() {
      return this.user && `${process.env.VUE_APP_URL}/#/users/id/${this.user.id}`;
    },
    profilePicture() {
      if (this.user && this.user.profilePhotoURL) {
        let pp = this.user.profilePhotoURL;
        if (!pp.startsWith('http')) {
          pp = `https://profile-clouthub.s3.amazonaws.com/${this.user.profilePhotoURL}`;
        }
        return pp
          .replace('profile-clouthub.s3.amazonaws.com', 'chprofile.backendcdn.com')
          .replace('profile-clouthub.s3.us-west-1.amazonaws.com', 'chprofile.backendcdn.com');
      }
      return null;
    },
    verificationImage() {
      if (this.user && this.user.verificationUrl) {
        let pp = this.user.verificationUrl;
        if (!pp.startsWith('http')) {
          pp = `https://forum-clouthub.s3.amazonaws.com/${this.user.verificationUrl}`;
        }
        return pp
          .replace('forum-clouthub.s3.amazonaws.com', 'chforum.backendcdn.com')
          .replace('forum-clouthub.s3.us-west-1.amazonaws.com', 'chforum.backendcdn.com');
      }
      return null;
    },
  },
  methods: {
    showModal() {
      this.$refs.VerifyModal.show();
    },
    async setBadgeType(badgeType, verificationStatus) {
      await this.$apollo.mutate({
        mutation: SetBadgeType,
        variables: {
          id: this.user.id,
          verificationStatus,
          badgeType,
          rejectionReason: this.rejectionReason,
        },
      });
      this.$refs.VerifyModal.hide();
      this.$emit('refresh');
    },
  },
};
</script>
<style scoped>
  .block-button
  {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
  .verification-image
  {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
  }
</style>
