<template>
  <div>
    <b-container>
      <b-spinner v-if="!user"></b-spinner>
      <div v-if="user">
        <app-page-header :title="displayName">
          <template #subtitle>
            Member since {{user.createdAt | moment('MMM Do YYYY')}}
          </template>
          <app-view-profile-button :user="user" />
          <app-delete-user-button :user="user" v-if="superAccess" />
        </app-page-header>
        <b-row>
          <b-col>
            <app-user-profile-card :user="user" class="mb-3" @refresh="refresh()" />
            <app-user-channel-card :user="user" class="mb-3" @refresh="refresh()" />
            <app-user-payment-card :user="user" v-if="superAccess" class="mb-3" @refresh="refresh()" />
            <app-user-complaint-card :user="user" class="mb-3" @refresh="refresh()" />
          </b-col>
          <b-col>
            <app-user-verification-card :user="user" class="mb-3" @refresh="refresh()" />
            <app-user-limits-card v-if="superAccess" :user="user" class="mb-3" @refresh="refresh()" />
            <app-user-password-card v-if="superAccess" :user="user" class="mb-3" @refresh="refresh()" />
            <app-user-sessions-card v-if="fullAccess" :user="user" class="mb-3" />
          </b-col>
          <b-col>
            <app-user-feed-card :user="user" class="mb-3" />
            <app-user-score-card :user="user" class="mb-3" />
            <!-- <app-user-metrics-card :user="user" class="mb-3" /> -->
            <app-user-status-card :user="user" class="mb-3" @refresh="refresh()" />
            <app-user-moderation-card :user="user" class="mb-3" />
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
import GetUser from '@/gql/users/GetUser.gql';
import $auth from '@/services/auth';

import AppPageHeader from '@/components/PageHeader.vue';
import AppViewProfileButton from '@/components/users/ViewProfileButton.vue';
import AppUserComplaintCard from '@/components/users/UserComplaintCard.vue';
import AppDeleteUserButton from '@/components/users/DeleteUserButton.vue';
import AppUserProfileCard from '@/components/users/UserProfileCard.vue';
import AppUserChannelCard from '@/components/users/UserChannelCard.vue';
import AppUserLimitsCard from '@/components/users/UserLimitsCard.vue';
import AppUserPasswordCard from '@/components/users/UserPasswordCard.vue';
import AppUserVerificationCard from '@/components/users/UserVerificationCard.vue';
// import AppUserMetricsCard from '@/components/users/UserMetricsCard.vue';
import AppUserStatusCard from '@/components/users/UserStatusCard.vue';
import AppUserModerationCard from '@/components/users/UserModerationCard.vue';
import AppUserSessionsCard from '@/components/users/UserSessionsCard.vue';
import AppUserScoreCard from '@/components/users/UserScoreCard.vue';
import AppUserPaymentCard from '@/components/users/UserPaymentCard.vue';
import AppUserFeedCard from '@/components/users/UserFeedCard.vue';

export default {
  components: {
    AppPageHeader,
    AppUserComplaintCard,
    AppViewProfileButton,
    AppDeleteUserButton,
    AppUserProfileCard,
    AppUserChannelCard,
    AppUserLimitsCard,
    AppUserPasswordCard,
    AppUserVerificationCard,
    // AppUserMetricsCard,
    AppUserStatusCard,
    AppUserModerationCard,
    AppUserSessionsCard,
    AppUserScoreCard,
    AppUserPaymentCard,
    AppUserFeedCard,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  apollo: {
    user: {
      query: GetUser,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },
  computed: {
    superAccess() {
      return $auth.isSuper();
    },
    fullAccess() {
      return $auth.accessType() === 'full';
    },
    displayName() {
      return this.user && `${this.user.displayname} (${this.user.username})`;
    },
  },
  methods: {
    refresh() {
      this.$apollo.queries.user.refetch();
    },
  },
};
</script>
