<template>
  <b-container>
    <app-page-header title="Reported Users">
      <b-button v-router-link="{name:'UserReportsWizard'}">Wizard Mode</b-button>
    </app-page-header>
    <b-table hover :items="reports" :fields="fields" @row-clicked="select" :busy="$apollo.queries.reports.loading">
      <template #busy>
        <b-spinner />
      </template>
      <template #empty>No user complaints left</template>
      <template #cell(user)="data">
        <p>{{data.item.user.username}}</p>
      </template>
    </b-table>
  </b-container>
</template>
<script>
import ListUserReports from '@/gql/reports/ListUserReports.gql';
import AppPageHeader from '@/components/PageHeader.vue';

export default {
  components: {
    AppPageHeader,
  },
  apollo: {
    reports: ListUserReports,
  },
  data() {
    return {
      fields: [
        'user',
        'total_reports',
        {
          key: 'latest_report',
          formatter: (value) => (this.$moment(value).format('LLL')),
        },
      ],
    };
  },
  methods: {
    select(report) {
      this.$router.push({
        name: 'UserDetail',
        params: report.user,
      });
    },
  },
};
</script>
