<template>
  <div>
    <b-container>
      <b-spinner v-if="!notification"></b-spinner>
      <div v-if="notification">
        <app-page-header :title="notification.status">
          <template #subtitle>
            Created: {{notification.createdAt | formatDate}}
          </template>
          <app-create-notification-button v-if="fullAccess" />
          <b-button variant="danger" v-if="fullAccess" @click="deleteNotification()">
            Delete
          </b-button>
        </app-page-header>
        <b-row>
          <b-col>
            <div>
              <b-card header="Notification">
                <table class="table table-sm table-borderless">
                  <tr>
                    <td>Status</td>
                    <td class="table-data">
                      {{notification.status}}
                    </td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td class="table-data">
                      {{notification.typeNotification}}
                    </td>
                  </tr>
                  <tr>
                    <td>Notification Message</td>
                    <td class="table-data">
                      {{notification.body}}
                    </td>
                  </tr>
                  <tr v-if="notification.url">
                    <td>Notification Url</td>
                    <td class="table-data">
                      <a :href="notification.url" target="_blank" >{{notification.url}}</a>
                    </td>
                  </tr>
                </table>
                <b-button v-if="fullAccess" variant="outline-primary" size="small" block @click="showNotificationModal()">Edit Notification</b-button>
              </b-card>
              <b-modal ref="NotificationModal" title="Edit Notification" @ok="saveNotification()">
                <app-notification-form :draft-notification="draftNotification"></app-notification-form>
              </b-modal>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
import $auth from '@/services/auth';

import AppPageHeader from '@/components/PageHeader.vue';
import AppCreateNotificationButton from '@/views/notifications/CreateNotificationButton.vue';
import GetNotification from '@/gql/notifications/GetNotification.gql';
import DeleteNotification from '@/gql/notifications/DeleteNotification.gql';
import UpdateNotification from '@/gql/notifications/UpdateNotification.gql';
import ListNotifications from '@/gql/notifications/ListNotifications.gql';
import AppNotificationForm from '@/components/notification/Form.vue';

export default {
  components: {
    AppPageHeader,
    AppCreateNotificationButton,
    AppNotificationForm,
  },
  data() {
    return {
      draftNotification: {},
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  apollo: {
    notification: {
      query: GetNotification,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },
  computed: {
    fullAccess() {
      return $auth.isAccessFull();
    },
  },
  methods: {
    showNotificationModal() {
      this.draftNotification = { ...this.notification };
      this.$refs.NotificationModal.show();
    },
    async saveNotification() {
      await this.$apollo.mutate({
        mutation: UpdateNotification,
        variables: this.draftNotification,
      });
    },
    async deleteNotification() {
      if (confirm('Are you sure you wish to delete this notification? This cannot be undone.')) {
        await this.$apollo.mutate({
          mutation: DeleteNotification,
          variables: {
            id: this.id,
          },
          refetchQueries: [
            {
              query: ListNotifications,
            },
          ],
        });
        this.$router.push({
          name: 'Notifications',
        });
      }
    },
  },
};
</script>

<style scoped>
.body__chars {
  margin-top: 3px;
  text-align: right;
  color: var(--cs-gray-04);
}

.table-data {
  word-break: break-word;
}
</style>
