<template>
  <b-card header="Queue">
    <b-spinner v-if="!posts" />
    <p v-if="posts">{{ posts.length }} in queue</p>
    <b-input v-model="query" placeholder="Filter by username" v-if="posts"/>
    <table class="table table-sm table-borderless" v-if="posts">
      <thead>
        <tr>
          <th>ID</th>
          <th>Username</th>
          <th>Type</th>
          <th>Uploaded</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="post in filteredResults" :key="post.id">
          <td>
            <a :href="'https://clouthub.com/p/' + post.id">{{ post.id }}</a>
          </td>
          <td>{{ post.postedByUser.username }}</td>
          <td>{{ post.type || 'forum' }} </td>
          <td>{{ post.createdAt | moment('from') }} </td>
        </tr>
      </tbody>
    </table>
  </b-card>
</template>
<script>
import GetTranscodingQueue from '@/gql/channels/GetTranscodingQueue.gql';

export default {
  apollo: {
    posts: GetTranscodingQueue,
  },
  data() {
    return {
      query: '',
    };
  },
  computed: {
    filteredResults() {
      return this.posts && this.posts.filter((p) => p.postedByUser.username.toLowerCase().includes(this.query.toLowerCase()));
    },
  },
};
</script>
