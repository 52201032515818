<template>
  <b-card header="Payments" class="mb-3">
    <table class="table table-sm table-borderless">
      <tr>
        <td>Enabled</td>
        <td class="text-right">
          {{draftParams.enablePayments || 'No'}}
        </td>
      </tr>
      <tr>
        <td>Name</td>
        <td class="text-right">
          {{draftParams.paymentName || 'Not Set'}}
        </td>
      </tr>
      <tr>
        <td>Address</td>
        <td class="text-right">
          {{draftParams.paymentAddress || 'Not Set'}}
        </td>
      </tr>
    </table>
    <b-button v-if="fullAccess" variant="outline-primary" size="small" block @click="showModal()">Edit Payments</b-button>
    <b-modal ref="Modal" @ok="saveScores()" title="Payment Settings">
      <b-form-group label="Enable Payments">
        <b-form-checkbox
          v-model="draftParams.enablePayments"
          :value="true"
          :unchecked-value="false"
          switch
        >
          Enable Payments
        </b-form-checkbox>
      </b-form-group>
      <b-form-group label="Payment Name">
        <b-form-input v-model="draftParams.paymentName" placeholder="Check payment name" :disabled="!draftParams.enablePayments"></b-form-input>
      </b-form-group>
      <b-form-group label="Payment Address">
        <b-form-textarea
          :disabled="!draftParams.enablePayments"
          v-model="draftParams.paymentAddress"
          placeholder="Check payment address"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>
    </b-modal>
  </b-card>
</template>
<script>
import $auth from '@/services/auth';
import SetUserPaymentSettings from '@/gql/users/SetUserPaymentSettings.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullAccess() {
      return $auth.accessType() === 'full';
    },
  },
  data() {
    return {
      draftParams: {
        enablePayments: this.user.enablePayments || false,
        paymentName: this.user.paymentName,
        paymentAddress: this.user.paymentAddress,
      },
    };
  },
  methods: {
    showModal() {
      this.$refs.Modal.show();
    },
    async saveScores() {
      console.log('Save Payment Settings', this.user);
      this.$apollo.mutate({
        mutation: SetUserPaymentSettings,
        variables: {
          userId: this.user.id,
          enablePayments: this.draftParams.enablePayments,
          paymentName: this.draftParams.paymentName,
          paymentAddress: this.draftParams.paymentAddress,
        },
      });
    },
  },
};
</script>
