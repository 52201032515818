<template>
  <div>
    <b-container>
      <app-page-header title="Transcoding Status">
      </app-page-header>
      <b-row>
        <b-col md="4">
          <app-transcoding-report :days="1" />
        </b-col>
        <b-col md="4">
          <app-transcoding-report :days="7" />
        </b-col>
        <b-col md="4">
          <app-transcoding-report :days="30" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <app-transcoding-queue />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import AppTranscodingReport from '@/components/channels/TranscodingReport.vue';
import AppTranscodingQueue from '@/components/channels/TranscodingQueue.vue';

export default {
  components: {
    AppPageHeader,
    AppTranscodingReport,
    AppTranscodingQueue,
  },
};
</script>
