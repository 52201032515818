<template>
  <b-card header="Channel">
    <div v-if="user.liveStreamKey">
      <table class="table table-sm table-borderless">
        <tr>
          <td>True news:</td>
          <td>
            <input type="checkbox"
                   :checked="user.channelTrueNews"
                   @change.prevent="markChannelTrueNews($event.target.checked)">
          </td>
        </tr>
        <tr>
          <td>URL:</td>
          <td>rtmp://rtmp.clouthub.com:5222/app</td>
        </tr>
        <tr>
          <td>Key:</td>
          <td>{{user.liveStreamKey}}</td>
        </tr>
      </table>
      <b-button v-if="fullAccess" variant="outline-danger" size="small" block @click="revokeLiveStreaming()">Revoke Live Streaming</b-button>
    </div>
    <div v-if="!user.liveStreamKey && fullAccess">
      <b-button variant="outline-primary" size="small" block @click="enableLiveStreaming()">Enable Live Streaming</b-button>
    </div>
  </b-card>
</template>

<script>
import EnableLiveStreaming from '@/gql/users/EnableLiveStreaming.gql';
import RevokeLiveStreaming from '@/gql/users/RevokeLiveStreaming.gql';
import UpdateChannelTrueNews from '@/gql/users/UpdateChannelTrueNews.gql';
import ListChannels from '@/gql/channels/ListChannels.gql';
import $auth from '@/services/auth';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullAccess() {
      return $auth.accessType() === 'full';
    },
  },
  methods: {
    markChannelTrueNews(channelTrueNews) {
      this.$apollo.mutate({
        mutation: UpdateChannelTrueNews,
        variables: {
          id: this.user.id,
          channelTrueNews,
        },
        refetchQueries: [
          { query: ListChannels },
        ],
      });
    },
    async enableLiveStreaming() {
      await this.$apollo.query({
        query: EnableLiveStreaming,
        variables: {
          userId: this.user.id,
        },
      });
      this.$emit('refresh');
    },
    async revokeLiveStreaming() {
      await this.$apollo.mutate({
        mutation: RevokeLiveStreaming,
        variables: {
          userId: this.user.id,
        },
      });
      this.$emit('refresh');
    },
  },
};
</script>
