<template>
  <div>
    <b-container>
      <app-page-header title="Recently Joined"></app-page-header>
      <b-spinner v-if="$apollo.queries.users.loading" />
      <b-table :items="users" @row-clicked="selectUser" :fields="tableFields" v-if="users" :busy="$apollo.queries.users.loading" hover >
        <template #cell(createdAt)="data">
          {{ data.item.createdAt | moment('from') }}
        </template>
        <template #cell(suspended)="data">
          <app-suspend-user-button :user="data.item" />
        </template>
      </b-table>
    </b-container>
  </div>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import ListRecentUsers from '@/gql/users/ListRecentUsers.gql';
import AppSuspendUserButton from '@/components/users/SuspendUserButton.vue';

export default {
  components: {
    AppPageHeader,
    AppSuspendUserButton,
  },
  data() {
    return {
      users: null,
      filterKey: 'username',
      filterValue: null,
      tableFields: [
        'username',
        'createdAt',
        'email',
        'displayname',
        'firstname',
        'lastname',
        'suspended',
      ],
    };
  },
  computed: {
    selectedUsers() {
      return this.users && this.users.filter((user) => user.selected);
    },
  },
  apollo: {
    users: {
      query: ListRecentUsers,
      update(resp) {
        const output = resp.users.map((user) => {
          const newUser = { ...user };
          newUser.suspended = newUser.suspended || false;
          newUser.selected = false;
          return newUser;
        });
        return output;
      },
    },
  },
  methods: {
    selectUser(user) {
      this.$router.push({
        name: 'UserDetail',
        params: user,
      });
    },
  },
};
</script>
