<template>
  <b-button @click="viewProfile()" variant="primary">View on CloutHub</b-button>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    viewProfile() {
      window.open(`${process.env.VUE_APP_URL}/#/users/id/${this.user.id}`);
    },
  },
};
</script>
