<template>
  <b-card header="Moderation History" class="mb-3">
    <div v-for="post in posts" :key="post.id" class="moderation-item">
      <div>
        <span>{{post.profanityStatus}}</span>
        <span v-if="post.profanityAdmin"> by {{post.profanityAdmin.username}}</span>
        <span> {{post.createdAt | moment('from')}}</span>
      </div>
      <div>{{post.bodyText}}</div>
      <div v-if="post.images">
        <div class="preview-img" v-for="img in post.images" :key="img.url">
          <img :src="cdn(img.url)" />
        </div>
      </div>
      <div v-if="post.video" class="video-holder">
        <app-video :src="post.video.url" />
      </div>
    </div>
  </b-card>
</template>
<script>
import ListUserModerationHistory from '@/gql/users/ListUserModerationHistory.gql';
import AppVideo from '@/components/Video.vue';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    AppVideo,
  },
  apollo: {
    posts: {
      query: ListUserModerationHistory,
      variables() {
        return {
          userId: this.user.id,
        };
      },
    },
  },
  methods: {
    cdn(url) {
      if (url.startsWith('us-west')) {
        return `https://chforum.backendcdn.com/${url}`;
      }
      if (url.includes('profile-clouthub.s3.us-west-1.amazonaws.com')) {
        return url.replace('profile-clouthub.s3.us-west-1.amazonaws.com', 'chprofile.backendcdn.com');
      }
      if (url.includes('d3029vgllsqvv6.cloudfront.net')) {
        return url.split('?')[0].replace('d3029vgllsqvv6.cloudfront.net', 'chforum.backendcdn.com');
      }
      return url;
    },
  },
};
</script>
<style scoped>
.moderation-item
{
  margin-bottom: 10px;
  border-bottom: solid 1px #efefef;
  padding-bottom: 10px;
}
.preview-img
{
  display: inline-block;
  width: 200px;
  height: 200px;
}
.preview-img img
{
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.video-holder
{
  width: 300px;
}
</style>
