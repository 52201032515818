<template>
  <b-card header="User Complaints" class="mb-3">
    <b-spinner v-if="!complaints" />
    <div v-if="complaints && !complaints.length">No Complaints</div>
    <div v-if="complaints && complaints.length">
      <div v-for="complaint in complaints" :key="complaint.id">
        <h5>{{complaint.createdAt | moment('from')}}</h5>
        <div>{{complaint.details}} / {{complaint.reason}}</div>
        <div v-if="complaint.user">Reported by {{complaint.user.displayname}}</div>
      </div>
      <b-button v-if="complaints.length" variant="outline-danger" @click="clearUserReports()" class="mt-3">Clear Complaints</b-button>
    </div>
  </b-card>
</template>
<script>
import ClearUserReports from '@/gql/reports/ClearUserReports.gql';
import ListUserComplaints from '@/gql/users/ListUserComplaints.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    complaints: {
      query: ListUserComplaints,
      variables() {
        return {
          userId: this.user.id,
        };
      },
    },
  },
  methods: {
    async clearUserReports() {
      await this.$apollo.mutate({
        mutation: ClearUserReports,
        variables: {
          id: this.user.id,
        },
      });
      this.$emit('refresh');
    },
  },
};
</script>
