<template>
  <b-row class="mt-3 mb-3">
    <b-col>
      <h3>{{title}}</h3>
        <slot name="subtitle"></slot>
    </b-col>
    <b-col>
      <div class="page-components">
        <slot></slot>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.page-components
{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
</style>
