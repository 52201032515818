import Vue from 'vue';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';

Vue.use(VueMoment, { moment });

// Accepts: '2023-07-01T00:00:00.000Z' returns '2023-07-01'
function getOnlyDate(strDateTime) {
  return strDateTime.slice(0, 10);
}

// Accepts: '2023-07-01T00:00:00.000Z' returns '00:00:00'
function getOnlyTime(strDateTime) {
  return strDateTime.slice(11, 19);
}

// Accepts: ('2023-07-01', '00:00:00') returns '2023-07-01T00:00:00.000Z'
function concatDateAndTime(strDate, strTime) {
  return `${strDate}T${strTime}.000Z`;
}

// Accepts: ('2023-07-01', '03:15:00') returns 2023-30-06T21:15:00.000Z
function concatDateAndTimeAndConvertToEST(strDate, strTime) {
  const mConcatDateAndTime = concatDateAndTime(strDate, strTime);
  const estDateTime = moment(mConcatDateAndTime).tz('America/New_York').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  return estDateTime;
}

// Accepts: (est) '2023-06-30T17:45:00.000Z' returns: (local) '2023-07-01T03:15:00.000Z'
function convertESTToLocal(strDateTime) {
  const localDateTime = moment(strDateTime).subtract(1, 'hours').subtract(30, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  return localDateTime;
}

export default {
  getOnlyDate,
  getOnlyTime,
  concatDateAndTime,
  concatDateAndTimeAndConvertToEST,
  convertESTToLocal,
};
