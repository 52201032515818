<template>
  <div>
    <b-container>
      <app-page-header title="Suspended Users">
        <b-input v-model="query" placeholder="Search suspended users" />
      </app-page-header>
      <b-table :items="displayUsers" :fields="fields" @row-clicked="viewUser" :busy="$apollo.queries.users.loading" hover  show-empty>
        <template #table-busy>
          <b-spinner />
        </template>
        <template #empty>
          No matching users
        </template>
      </b-table>
    </b-container>
  </div>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import FindSuspendedUsers from '@/gql/suspendeds/FindSuspendedUsers.gql';

export default {
  components: {
    AppPageHeader,
  },
  apollo: {
    users: {
      query: FindSuspendedUsers,
      variables() {
        return {
          query: this.query,
        };
      },
    },
  },
  computed: {
    displayUsers() {
      return this.users && this.users.filter((u) => (!!u.user));
    },
  },
  methods: {
    viewUser(item) {
      this.$router.push({ name: 'UserDetail', params: { id: item.userId } });
    },
  },
  data() {
    return {
      query: '',
      fields: [
        {
          key: 'username',
          label: 'Username',
          formatter: (value, key, item) => (item.user && item.user.username),
        },
        {
          key: 'email',
          label: 'Email',
          formatter: (value, key, item) => (item.user && item.user.email),
        },
        {
          key: 'createdAt',
          label: 'Suspension Date',
          formatter: (value) => (this.$moment(value).format('LLL')),
        },
        {
          key: 'adminname',
          label: 'Suspended by',
          formatter: (value, key, item) => (item.admin ? item.admin.displayname : 'Unknown'),
        },
        'reason',
      ],
    };
  },
};
</script>
