<template>
  <div>
    <b-card header="Profile">
      <table class="table table-sm table-borderless">
        <tr>
          <td>First Name</td>
          <td>
            {{user.firstname}}
          </td>
        </tr>
        <tr>
          <td>Last Name</td>
          <td>
            {{user.lastname}}
          </td>
        </tr>
        <tr>
          <td>Username</td>
          <td>
            {{user.username}}
          </td>
        </tr>
        <tr>
          <td>Email</td>
          <td>
            {{user.email}}
          </td>
        </tr>
        <tr>
          <td>Display Name</td>
          <td>
            {{user.displayname}}
          </td>
        </tr>
        <tr>
          <td>Phone Number</td>
          <td>
            {{user.phoneNo || '-'}}
          </td>
        </tr>
      </table>
      <b-button v-if="fullAccess" variant="outline-primary" size="small" block @click="showProfileModal()">Edit Profile</b-button>
    </b-card>
    <b-modal ref="ProfileModal" @ok="saveProfile()">
      <b-form-group label="First Name">
        <b-form-input v-model="draftUser.firstname" placeholder="First Name"></b-form-input>
      </b-form-group>
      <b-form-group label="Last Name">
        <b-form-input v-model="draftUser.lastname" placeholder="Last Name"></b-form-input>
      </b-form-group>
      <b-form-group label="Username">
        <b-form-input v-model="draftUser.username" placeholder="Username"></b-form-input>
      </b-form-group>
      <b-form-group label="Email">
        <b-form-input v-model="draftUser.email" placeholder="Email"></b-form-input>
      </b-form-group>
      <b-form-group label="Display Name">
        <b-form-input v-model="draftUser.displayname" placeholder="Display Name"></b-form-input>
      </b-form-group>
      <b-form-group label="Phone Number">
        <b-form-input v-model="draftUser.phoneNo" placeholder="Phone Number"></b-form-input>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import UpdateUser from '@/gql/users/UpdateUser.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      draftUser: {},
    };
  },
  computed: {
    fullAccess() {
      return $auth.accessType() === 'full';
    },
  },
  methods: {
    showProfileModal() {
      this.draftUser = { ...this.user };
      this.$refs.ProfileModal.show();
    },
    async saveProfile() {
      await this.$apollo.mutate({
        mutation: UpdateUser,
        variables: this.draftUser,
      });
      this.$emit('refresh');
    },
  },
};
</script>
