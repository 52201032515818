<template>
  <div>
    <b-container>
      <app-page-header title="Sponsored Areas">
        <b-form>
          <b-input v-model="query" />
        </b-form>
        <b-button @click="openAddModal()" variant="primary">Add Sponsored Area</b-button>
      </app-page-header>
      <div class="menuTabs">
        <div v-for="tab in menuTabs" v-bind:Key="tab.key" :class="activeMenuTab === tab.key? 'menuTab-active' : 'menuTab'" @click=toggleTab(tab.key)>
            <p>{{ tab.value }}</p>
        </div>
      </div>
      <template>
        <b-table hover :items="filteredAdZones" :fields="fields" @row-clicked="select" :filter="query" show-empty :busy="$apollo.queries.adZones.loading">
          <template #table-busy>
            <b-spinner />
          </template>
          <template #cell(Actions)="data">
            <b-button @click="openEditModal(data.item)" variant="primary">Edit</b-button>
            <b-button @click="deleteAdZone(data.item)" variant="btn btn-danger btn-small">X</b-button>
          </template>
        </b-table>
        <!-- Edit Model -->
        <b-modal ref="AddEditSponsoredAreasModal" @ok="AddUpdateSponsoredArea()" @cancel="closeEditModal()">
        <b-form-group label="Zone Name">
            <b-form-input v-model="draftItem.zone_name"></b-form-input>
        </b-form-group>
        <b-form-group label="Area">
            <b-form-input v-model="draftItem.area"></b-form-input>
        </b-form-group>
        <b-form-group label="Ad Size">
            <b-form-input v-model="draftItem.ad_size"></b-form-input>
        </b-form-group>
        <b-form-group label="Value">
            <b-form-input v-model="draftItem.value"></b-form-input>
        </b-form-group>
        <b-form-group label="Invocation Code">
            <b-form-input v-model="draftItem.invocation_code"></b-form-input>
        </b-form-group>
        <b-form-group label="Revive Id">
            <b-form-input v-model="draftItem.revive_id"></b-form-input>
        </b-form-group>
        <b-form-group label="Admin Group">
            <b-select v-model="draftItem.admin_group">
            <b-select-option value="F">Forum</b-select-option>
            <b-select-option value="C">Channels</b-select-option>
            <b-select-option value="G">Groups</b-select-option>
            <b-select-option value="P">People</b-select-option>
            <b-select-option value="M">Meetings</b-select-option>
            <b-select-option value="E">Events</b-select-option>
            </b-select>
        </b-form-group>
        <b-form-group label="Display Start">
            <b-form-datepicker v-model="draftItem.display_start" :time="true"></b-form-datepicker>
        </b-form-group>
        <b-form-group label="Display End">
            <b-form-datepicker v-model="draftItem.display_end" :time="true"></b-form-datepicker>
        </b-form-group>
        <b-form-group label="Active">
            <b-form-checkbox v-model="draftItem.active" switch>Active</b-form-checkbox>
        </b-form-group>
        </b-modal>
      </template>
    </b-container>
  </div>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import GetAllAdZones from '@/gql/adZones/GetAllAdZones.gql';
import UpdateAdZone from '@/gql/adZones/UpdateAdZone.gql';
import CreateAdZone from '@/gql/adZones/CreateAdZone.gql';
import DeleteAdZone from '@/gql/adZones/DeleteAdZone.gql';

export default {
  components: {
    AppPageHeader,
  },
  apollo: {
    adZones: {
      query: GetAllAdZones,
    },
  },
  data() {
    return {
      query: '',
      filteredAdZones: [],
      fields: [
        'zone_name',
        'area',
        'invocation_code',
        'Actions',
      ],
      isEditModalOpen: false,
      draftItem: {
        id: 0,
        zone_name: '',
        invocation_code: '',
        revive_id: '',
        area: '',
        ad_size: '',
        value: '',
        admin_group: '',
        active: true,
        display_start: new Date(),
        display_end: new Date(),
      },
      formType: 'edit',
      activeMenuTab: 'F',
      menuTabs: [
        {
          key: 'F',
          value: 'Forum',
        },
        {
          key: 'C',
          value: 'Channels',
        },
        {
          key: 'G',
          value: 'Groups',
        },
        {
          key: 'P',
          value: 'People',
        },
        {
          key: 'M',
          value: 'Meetings',
        },
        {
          key: 'E',
          value: 'Events',
        },
      ],
    };
  },
  methods: {
    select(row) {
      this.$router.push({
        name: 'UserDetail',
        params: row,
      });
    },

    async toggleTab(key) {
      this.activeMenuTab = key;
      this.filteredAdZones = this.adZones.filter((x) => x.admin_group === this.activeMenuTab);
    },

    openAddModal() {
      this.formType = 'add';
      this.draftItem.id = null;
      this.draftItem.zone_name = null;
      this.draftItem.invocation_code = null;
      this.draftItem.revive_id = null;
      this.draftItem.area = null;
      this.draftItem.ad_size = null;
      this.draftItem.value = null;
      this.draftItem.admin_group = null;
      this.draftItem.active = true;
      this.draftItem.display_start = new Date();
      this.draftItem.display_end = new Date();
      this.$refs.AddEditSponsoredAreasModal.show();
    },

    openEditModal(item) {
      this.formType = 'edit';
      this.draftItem.id = item.id;
      this.draftItem.zone_name = item.zone_name;
      this.draftItem.invocation_code = item.invocation_code;
      this.draftItem.revive_id = item.revive_id;
      this.draftItem.area = item.area;
      this.draftItem.ad_size = item.ad_size;
      this.draftItem.value = item.value;
      this.draftItem.admin_group = item.admin_group;
      this.draftItem.active = item.active;
      this.draftItem.display_start = item.display_start;
      this.draftItem.display_end = item.display_end;
      this.$refs.AddEditSponsoredAreasModal.show();
    },

    async AddUpdateSponsoredArea() {
      const variables = {
        zone_name: this.draftItem.zone_name,
        invocation_code: this.draftItem.invocation_code,
        revive_id: this.draftItem.revive_id,
        area: this.draftItem.area,
        ad_size: this.draftItem.ad_size,
        value: this.draftItem.value,
        admin_group: this.draftItem.admin_group,
        active: this.draftItem.active,
        display_start: this.draftItem.display_start,
        display_end: this.draftItem.display_end,
      };
      if (this.formType === 'add') {
        await this.$apollo.mutate({
          mutation: CreateAdZone,
          variables,
        });
      } else if (this.formType === 'edit') {
        variables.id = this.draftItem.id;
        await this.$apollo.mutate({
          mutation: UpdateAdZone,
          variables,
        });
      }
      await this.$apollo.queries.adZones.refetch();
      await this.toggleTab(this.activeMenuTab);
      this.closeEditModal();
    },

    async deleteAdZone(item) {
      if (confirm('Are you sure you wish to delete this AdZone? This cannot be undone.')) {
        await this.$apollo.mutate({
          mutation: DeleteAdZone,
          variables: {
            id: item.id,
          },
        });
        await this.$apollo.queries.adZones.refetch();
        await this.toggleTab(this.activeMenuTab);
      }
    },

    closeEditModal() {
      this.isEditModalOpen = false;
    },
  },
  async mounted() {
    await this.$apollo.queries.adZones.refetch();
    this.toggleTab('F');
  },
};
</script>

<style>
td[aria-colindex="1"] {
  max-width: 140px;
}
td[aria-colindex="2"] {
  max-width: 300px;
}
td[aria-colindex="3"] {
  max-width: 700px;
  max-height: 200px;
  overflow: auto;
}
td[aria-colindex="4"] {
  display: flex;
  gap: 5px;
}
.menuTabs {
    display: flex;
    gap: 0px;
    background-color: #d6d6d6;
    width: fit-content;
}
.menuTabs p {
    margin: 0px;
    padding: 5px 15px;
    font-weight: 600;
}
.menuTabs .menuTab-active {
    background-color: #047bfb;
    color: white;
}
</style>
