<template>
  <div>
    <b-container>
      <app-page-header title="Featured Posts">
        <b-form>
          <b-input v-model="query" />
        </b-form>
        <b-button v-if="!isHistoryMode" @click="isHistoryMode = !isHistoryMode" variant="primary">View Slots History</b-button>
        <b-button v-if="isHistoryMode" @click="isHistoryMode = !isHistoryMode" variant="primary">View Active Slots</b-button>
        <b-button @click="openAddModal()" variant="primary">Add Slot</b-button>
      </app-page-header>
      <template>
        <!-- Active Slots View -->
        <b-table v-if="!isHistoryMode" hover :items="featuredPosts" :fields="fields" @row-clicked="select" :filter="query" show-empty :busy="$apollo.queries.featuredPosts.loading">
          <template #table-busy>
            <b-spinner />
          </template>
          <template #cell(Actions)="data">
            <b-button @click="openEditModal(data.item)" variant="primary">Edit</b-button>
          </template>
        </b-table>
        <!-- Slots History View -->
        <b-table v-if="isHistoryMode" hover :items="featuredPostsLog" :fields="historyFields" @row-clicked="select" :filter="query" show-empty :busy="$apollo.queries.featuredPostsLog.loading">
          <template #table-busy>
            <b-spinner />
          </template>
          <template #cell(Actions)="data">
            <b-button @click="select(data.item)" variant="primary">View Details Log</b-button>
          </template>
        </b-table>
        <!-- Edit Model -->
        <b-modal ref="AddEditFeaturedPostModal" @ok="AddUpdateFeaturedPost()" @cancel="closeEditModal()">
        <b-form-group label="Slot">
            <b-form-input v-model="draftItem.slot" disabled></b-form-input>
        </b-form-group>
        <b-form-group label="Position">
            <b-form-input v-model="draftItem.position" disabled></b-form-input>
        </b-form-group>
        <b-form-group label="PostId">
            <b-form-input v-model="draftItem.postid"></b-form-input>
        </b-form-group>
        <b-form-group class="datetime-input" label="Display Start">
          <b-form-datepicker v-model="draftItem.display_start_date" :time="true"></b-form-datepicker>
          <b-form-timepicker v-model="draftItem.display_start_time" :time="true"></b-form-timepicker>
        </b-form-group>
        <b-form-group class="datetime-input" label="Display End">
            <b-form-datepicker v-model="draftItem.display_end_date" :time="true"></b-form-datepicker>
            <b-form-timepicker v-model="draftItem.display_end_time" :time="true"></b-form-timepicker>
        </b-form-group>
        <b-form-group label="Status">
            <b-form-checkbox v-model="draftItem.status" switch></b-form-checkbox>
        </b-form-group>
        </b-modal>
      </template>
    </b-container>
  </div>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import ListFeaturedPosts from '@/gql/channels/ListFeaturedPosts.gql';
import ListFeaturedPostsLog from '@/gql/channels/ListFeaturedPostsLog.gql';
import UpdateFeaturedPost from '@/gql/channels/UpdateFeaturedPost.gql';
import AddFeaturedPostSlot from '@/gql/channels/AddFeaturedPostSlot.gql';
import $utilities from '@/services/utilities';

export default {
  components: {
    AppPageHeader,
  },
  apollo: {
    featuredPosts: {
      query: ListFeaturedPosts,
    },
    featuredPostsLog: {
      query: ListFeaturedPostsLog,
    },
  },
  data() {
    return {
      query: '',
      fields: [
        'slot',
        'position',
        'postid',
        'Actions',
      ],
      historyFields: [
        'postid',
        'slot',
        'position',
        'Actions',
      ],
      isEditModalOpen: false,
      draftItem: {
        id: '',
        slot: '',
        position: '',
        postid: '',
        status: true,
        display_start_date: '2023-01-01',
        display_start_time: '00:00:00',
        display_end_date: new Date(),
        display_end_time: '2023-12-31',
      },
      formType: 'edit',
      isHistoryMode: false,
    };
  },
  methods: {
    select(row) {
      this.$router.push({
        name: 'FeaturedPostDetail',
        params: row,
      });
    },

    openAddModal() {
      this.formType = 'add';
      const slot = this.featuredPosts.reduce((max, obj) => (obj.slot > max ? obj.slot : max), 0);
      const position = this.featuredPosts.reduce((max, obj) => (obj.position > max ? obj.position : max), -3);
      this.draftItem.id = null;
      this.draftItem.slot = slot + 1;
      this.draftItem.position = position + 5;
      this.draftItem.postid = '';
      this.draftItem.status = true;
      this.draftItem.display_start_date = '2023-01-01';
      this.draftItem.display_start_time = '00:00:00';
      this.draftItem.display_end_date = '2023-12-31';
      this.draftItem.display_end_time = '23:59:00';
      this.$refs.AddEditFeaturedPostModal.show();
    },

    openEditModal(item) {
      this.formType = 'edit';
      this.draftItem.id = item.id;
      this.draftItem.slot = item.slot;
      this.draftItem.position = item.position;
      this.draftItem.postid = item.postid;
      this.draftItem.status = item.status;
      // const displayStart = $utilities.convertESTToLocal(item.display_start);
      // const displayEnd = $utilities.convertESTToLocal(item.display_end);
      this.draftItem.display_start_date = $utilities.getOnlyDate(item.display_start);
      this.draftItem.display_start_time = $utilities.getOnlyTime(item.display_start);
      this.draftItem.display_end_date = $utilities.getOnlyDate(item.display_end);
      this.draftItem.display_end_time = $utilities.getOnlyTime(item.display_end);
      this.$refs.AddEditFeaturedPostModal.show();
    },

    async AddUpdateFeaturedPost() {
      const variables = {
        slot: this.draftItem.slot,
        position: this.draftItem.position,
        postId: this.draftItem.postid,
        status: this.draftItem.status,
        display_start: $utilities.concatDateAndTime(this.draftItem.display_start_date, this.draftItem.display_start_time),
        display_end: $utilities.concatDateAndTime(this.draftItem.display_end_date, this.draftItem.display_end_time),
      };
      if (this.formType === 'add') {
        await this.$apollo.mutate({
          mutation: AddFeaturedPostSlot,
          variables,
        });
      } else if (this.formType === 'edit') {
        variables.id = this.draftItem.id;
        await this.$apollo.mutate({
          mutation: UpdateFeaturedPost,
          variables,
        });
      }
      await this.$apollo.queries.featuredPosts.refetch();
      this.closeEditModal();
    },

    closeEditModal() {
      this.isEditModalOpen = false;
    },
  },
};
</script>

<style>
.datetime-input > div:first-of-type {
  display: flex;
  gap: 10px;
}
</style>
