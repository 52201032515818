<template>
  <div>
    <b-button variant="primary" @click="showCreateModal()">+ Create Notification</b-button>
    <b-modal ref="CreateNotificationModal" title="Create Notification" @ok="saveNotification()">
      <app-notification-form :draft-notification="draftNotification"></app-notification-form>
    </b-modal>
  </div>
</template>
<script>
import CreateNotification from '@/gql/notifications/CreateNotification.gql';
import ListNotifications from '@/gql/notifications/ListNotifications.gql';
import AppNotificationForm from '@/components/notification/Form.vue';

export default {
  components: {
    AppNotificationForm,
  },
  data() {
    return {
      draftNotification: {},
    };
  },
  methods: {
    showCreateModal() {
      this.$refs.CreateNotificationModal.show();
    },
    async saveNotification() {
      try {
        if (!this.draftNotification.status || !this.draftNotification.typeNotification) {
          throw new Error('Missing one or more required parameters');
        }
        const newNotification = await this.$apollo.mutate({
          mutation: CreateNotification,
          variables: this.draftNotification,
          refetchQueries: [
            {
              query: ListNotifications,
            },
          ],
        });
        this.draftNotification = {};
        this.selectNotification(newNotification.data.create_notification);
      } catch (err) {
        this.$bvModal.msgBoxOk(err.message);
      }
    },
    selectNotification(notification) {
      this.$router.push({
        name: 'NotificationDetail',
        params: {
          id: notification.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.body__chars {
  margin-top: 3px;
  text-align: right;
  color: var(--cs-gray-04);
}
</style>
