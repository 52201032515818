<template>
  <div>
    <b-container>
      <app-page-header title="Find Users">
        <app-create-user-button />
      </app-page-header>
      <b-form @submit.prevent="findUsers()" class="mb-4">
        <b-row>
          <b-col md="3">
            <b-form-select v-model="filterKey" :options="searchOptions" />
          </b-col>
          <b-col md="9">
            <b-form-input v-model="filterValue" />
          </b-col>
        </b-row>
      </b-form>
      <b-spinner v-if="finding" />
      <b-table :items="users" @row-clicked="selectUser" :fields="tableFields" v-if="users" :busy="finding" hover />
    </b-container>
  </div>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import AppCreateUserButton from '@/components/users/CreateUserButton.vue';
import FindUsers from '@/gql/users/FindUsers.gql';

export default {
  components: {
    AppPageHeader,
    AppCreateUserButton,
  },
  data() {
    return {
      finding: false,
      users: null,
      filterKey: 'username',
      filterValue: null,
      searchOptions: [
        { value: 'username', text: 'Search by Username' },
        { value: 'email', text: 'Search by Email' },
        { value: 'phoneNo', text: 'Search by Phone Number' },
        { value: 'displayname', text: 'Search by Display Name' },
        { value: 'firstname', text: 'Search by First Name' },
        { value: 'lastname', text: 'Search by Last Name' },
      ],
      tableFields: [
        'username',
        'email',
        'phoneNo',
        'displayname',
        'firstname',
        'lastname',
      ],
    };
  },
  methods: {
    async findUsers() {
      this.finding = true;
      const resp = await this.$apollo.query({
        query: FindUsers,
        variables: {
          filterKey: this.filterKey,
          filterValue: this.filterValue,
        },
      });
      this.finding = false;
      this.users = resp.data.users;
    },
    selectUser(user) {
      this.$router.push({
        name: 'UserDetail',
        params: user,
      });
    },
  },
};
</script>
