<template>
  <b-container>
    <app-page-header title="Notifications">
      <app-create-notification-button v-if="fullAccess"/>
    </app-page-header>
    <b-table :items="notifications" :fields="fields" show-empty
             :busy="$apollo.queries.notifications.loading">
      <template #table-busy>
        <b-spinner/>
      </template>
      <template #empty>No notifications left to review</template>
      <template #cell(notification)="data">
        <div>{{ data.item.body }}</div>
        <div>type: {{ data.item.typeNotification }}</div>
        <div v-if="data.item.url">url: <a target="_blank" :href="data.item.url">{{data.item.url}}</a></div>
        <div>created: {{ data.item.createdAt | formatDate }}</div>
      </template>
      <template #cell(actions)="data">
        <b-button block variant="success"
                  v-if="data.item.status === 'deactivated'"
                  @click="activate(data.item)">Publish
        </b-button>
        <b-button block variant="danger" v-if="data.item.status === 'active'"
                  @click="deactivate(data.item)">Deactivate
        </b-button>
        <b-button block variant="info" @click="preview(data.item)">Preview</b-button>
      </template>
    </b-table>
  </b-container>
</template>
<script>
import ListNotifications from '@/gql/notifications/ListNotifications.gql';
import AppPageHeader from '@/components/PageHeader.vue';
import AppCreateNotificationButton from '@/views/notifications/CreateNotificationButton.vue';
import $auth from '@/services/auth';
import UpdateNotification from '@/gql/notifications/UpdateNotification.gql';

export default {
  components: {
    AppPageHeader,
    AppCreateNotificationButton,
  },
  data() {
    return {
      fields: [
        'notification',
        'actions',
      ],
      currentItem: null,
    };
  },
  computed: {
    fullAccess() {
      return $auth.isAccessFull();
    },
  },
  apollo: {
    notifications: {
      query: ListNotifications,
    },
  },
  methods: {
    async setStatus(notification, status) {
      await this.$apollo.mutate({
        mutation: UpdateNotification,
        variables: {
          ...notification,
          status,
        },
      });
      this.$apollo.queries.notifications.refetch();
    },
    activate(item) {
      if (confirm('Are you sure you wish to publish this notification?')) {
        this.setStatus(item, 'active');
      }
    },
    deactivate(item) {
      if (confirm('Are you sure you wish to deactivate this notification?')) {
        this.setStatus(item, 'deactivated');
      }
    },
    preview(notification) {
      this.$router.push({
        name: 'NotificationDetail',
        params: {
          id: notification.id,
        },
      });
    },
  },
};
</script>
<style scoped>

</style>
