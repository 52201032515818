<template>
  <b-container>
#TOTAL NUMBER OF USERS IN SYSTEM:<br>
#NEW SIGN UPS:<br>#ACCOUNTS SUSPENDED:<br># MODERATIONS OPEN:<br>#TOTAL LIVE STREAMS<br>#MOST ENGAGED POST<br>#USER WITH MOST FOLLOWERS<br>#USER WITH MOST FRIENDS<br>#USER WITH MOST POSTS<br>#USER WITH MOST COMMENTS<br>
  </b-container>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
};
</script>
