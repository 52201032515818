<template>
  <div>
    <b-button variant="primary" @click="showCreateModal()">+ Create User</b-button>
    <b-modal ref="CreateUserModal" title="Create User" @ok="saveUser()">
      <b-form-group label="Email*">
        <b-form-input v-model="draftUser.email" placeholder="Email"></b-form-input>
      </b-form-group>
      <b-form-group label="Username*">
        <b-form-input v-model="draftUser.username" placeholder="Username"></b-form-input>
      </b-form-group>
      <b-form-group label="Display Name">
        <b-form-input v-model="draftUser.displayname" placeholder="Display Name"></b-form-input>
      </b-form-group>
      <b-form-group label="First Name">
        <b-form-input v-model="draftUser.firstname" placeholder="First Name"></b-form-input>
      </b-form-group>
      <b-form-group label="Last Name">
        <b-form-input v-model="draftUser.lastname" placeholder="Last Name"></b-form-input>
      </b-form-group>
      <b-form-group label="Password">
        <b-form-input v-model="draftUser.password" placeholder="Password"></b-form-input>
      </b-form-group>
      <!-- <b-form-group label="Gender">
       <b-form-radio-group
         v-model="draftUser.gender"
         :options="['Male', 'Female']"
        ></b-form-radio-group>
     </b-form-group>
      <b-form-group label="Phone Number">
        <b-form-input v-model="draftUser.phoneNo" placeholder="Phone Number"></b-form-input>
      </b-form-group> -->
    </b-modal>
  </div>
</template>
<script>
import CreateUser from '@/gql/users/CreateUser.gql';

export default {
  data() {
    return {
      draftUser: {},
    };
  },
  methods: {
    showCreateModal() {
      this.$refs.CreateUserModal.show();
    },
    async saveUser() {
      try {
        if (!this.draftUser.username || !this.draftUser.email) {
          throw new Error('Missing one or more required parameters');
        }
        if (!this.draftUser.username.startsWith('@')) {
          this.draftUser.username = `@${this.draftUser.username}`;
        }
        if (!this.draftUser.displayname) {
          this.draftUser.displayname = this.draftUser.username.replace('@', '');
        }

        const newUser = await this.$apollo.mutate({
          mutation: CreateUser,
          variables: this.draftUser,
        });
        this.draftUser = {};
        this.selectUser(newUser.data.user);
      } catch (err) {
        this.$bvModal.msgBoxOk(err.message);
      }
    },
    selectUser(user) {
      this.$router.push({
        name: 'UserDetail',
        params: user,
      });
    },
  },
};
</script>
