<template>
  <div class="full-content">
    <b-spinner v-if="!report"></b-spinner>
    <div class="control-bar" v-if="report">
      <span>Post Reports: </span>
      <span>({{report.total_reports}} complaints | Last reported {{report.latest_report | moment('from')}})</span>
      <span class="flex-1"></span>
      <b-button variant="danger" @click="deletePost()">Delete Post</b-button>
      <b-button variant="success" @click="clearReports()">Clear Reports</b-button>
    </div>
    <div class="flex-1">
      <iframe :src="reportSrc"></iframe>
    </div>
  </div>
</template>
<script>
import ListPostReports from '@/gql/reports/ListPostReports.gql';
import ClearPostReports from '@/gql/reports/ClearPostReports.gql';
import DeletePost from '@/gql/reports/DeletePost.gql';

export default {
  data() {
    return {
      report: null,
      reports: [],
    };
  },
  computed: {
    reportSrc() {
      return this.report && `${process.env.VUE_APP_URL}/#/posts/${this.report.id}`;
    },
  },
  methods: {
    async next() {
      if (!this.reports.length) {
        const resp = await this.$apollo.query({
          query: ListPostReports,
        });
        console.log(resp);
        this.reports = [...resp.data.reports];
        console.log(this.reports);
      }
      this.report = this.reports.pop();
      console.log(this.report);
    },
    async clearReports() {
      await this.$apollo.mutate({
        mutation: ClearPostReports,
        variables: {
          id: this.report.id,
        },
      });
      this.next();
    },
    async deletePost() {
      await this.$apollo.mutate({
        mutation: DeletePost,
        variables: {
          id: this.report.id,
        },
      });
      await this.clearReports();
    },
  },
  mounted() {
    this.next();
  },
};
</script>
<style scoped>
.control-bar
{
  background-color: #555;
  color: white;
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
}
</style>
