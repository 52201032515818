<template>
  <b-container>
    <app-page-header title="Meeting Live Stream"></app-page-header>
    <b-spinner v-if="!meetings" />
    <b-row v-else>
      <b-col>
        <table class="table">
          <thead>
            <tr>
              <th>Meeting Name</th>
              <th>Livestream URL</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="meeting of meetings" :key="meeting.id">
              <td>{{ meeting.name }}</td>
              <td>{{ meeting.liveStreamUrl || 'Not Set'}}</td>
              <td>
                <b-button v-if="!meeting.liveStreamUrl" @click="connect(meeting.id)">Connect</b-button>
                <b-button v-else @click="clear(meeting.id)" variant="danger">Clear</b-button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="!meetings.length">No upcoming meetings</div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import ListScheduledMeetings from '@/gql/meetings/ListScheduledMeetings.gql';
import CopyMeetingLivestreamFromUser from '@/gql/meetings/CopyMeetingLivestreamFromUser.gql';
import ClearMeetingLivestream from '@/gql/meetings/ClearMeetingLivestream.gql';

export default {
  components: {
    AppPageHeader,
  },
  apollo: {
    meetings: ListScheduledMeetings,
  },
  methods: {
    async connect(meetingId) {
      const username = prompt('What username?');
      if (!username) {
        return false;
      }
      await this.$apollo.mutate({
        mutation: CopyMeetingLivestreamFromUser,
        variables: {
          username,
          meetingId,
        },
      });
      alert('Done');
      return true;
    },
    async clear(meetingId) {
      await this.$apollo.mutate({
        mutation: ClearMeetingLivestream,
        variables: {
          meetingId,
        },
      });
      alert('Done');
    },
  },
};
</script>
<style scoped></style>
