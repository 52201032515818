<template>
  <div>
    <b-container>
      <app-page-header title="Live Channels">
        <b-form>
          <b-input v-model="query" />
        </b-form>
      </app-page-header>
      <template>
        <b-table hover :items="users" :fields="fields" @row-clicked="select" :filter="query" show-empty :busy="$apollo.queries.users.loading">
          <template #table-busy>
            <b-spinner />
          </template>
          <template #head(channelTrueNews)>
            <span>True News</span>
          </template>
          <template #cell(channelTrueNews)="data">
            <span>{{ data.item.channelTrueNews ? 'Yes' : 'No' }}</span>
          </template>
          <template #empty>No live channels</template>
          <template #emptyfiltered>No matching live channels</template>
        </b-table>
      </template>
    </b-container>
  </div>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import ListChannels from '@/gql/channels/ListChannels.gql';

export default {
  components: {
    AppPageHeader,
  },
  apollo: {
    users: {
      query: ListChannels,
    },
  },
  data() {
    return {
      query: '',
      fields: [
        'username',
        'channelName',
        'channelTrueNews',
        'channelNumber',
        'liveStreamKey',
      ],
    };
  },
  methods: {
    select(row) {
      this.$router.push({
        name: 'UserDetail',
        params: row,
      });
    },
  },
};
</script>
