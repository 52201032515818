<template>
  <b-card header="Verification Status" class="mb-3">
    <p>{{user.badgeType || 'none' }}</p>
    <app-verify-user-button :user="user" @refresh="refresh()" v-if="fullAccess" />
  </b-card>
</template>
<script>
import $auth from '@/services/auth';
import AppVerifyUserButton from './VerifyUserButton.vue';

export default {
  components: {
    AppVerifyUserButton,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullAccess() {
      return $auth.accessType() === 'full';
    },
  },
  methods: {
    refresh() {
      this.$emit('refresh');
    },
  },
};
</script>
