<template>
  <b-container>
    <app-page-header title="Blocked Words">
      <b-form>
        <b-input v-model="query" placeholder="Filter Words"></b-input>
      </b-form>
      <b-button @click="showModal()" variant="primary">+ Block New Word</b-button>
    </app-page-header>
    <b-spinner v-if="!words" />
    <b-table :items="displayWords" :fields="fields" v-if="words">
      <template #cell(score)="data">
        <b-select :value="data.item.score" @input="updateScore($event, data.item)">
          <b-select-option :value="1">Blocked</b-select-option>
          <b-select-option :value="2">Under Review</b-select-option>
          <b-select-option :value="3">Allowed</b-select-option>
        </b-select>
      </template>
      <template #cell(actions)="data">
        <b-button @click="deleteWord(data.item)" size="small" variant="danger">Remove</b-button>
      </template>
    </b-table>
    <b-modal ref="BlockedWordModal" ok-only @ok="createWord()">
      <b-form-group label="Word">
        <b-form-input v-model="draftWord.word"></b-form-input>
      </b-form-group>
      <b-form-group label="Rating">
        <b-select v-model="draftWord.score">
          <b-select-option :value="1">Blocked</b-select-option>
          <b-select-option :value="2">Under Review</b-select-option>
          <b-select-option :value="3">Allowed</b-select-option>
        </b-select>
      </b-form-group>
    </b-modal>
  </b-container>
</template>
<script>
import ListRestrictedWords from '@/gql/restrictedWords/ListRestrictedWords.gql';
import InsertRestrictedWord from '@/gql/restrictedWords/InsertRestrictedWord.gql';
import DeleteRestrictedWord from '@/gql/restrictedWords/DeleteRestrictedWord.gql';
import UpdateRestrictedWord from '@/gql/restrictedWords/UpdateRestrictedWord.gql';
import AppPageHeader from '@/components/PageHeader.vue';

export default {
  components: {
    AppPageHeader,
  },
  data() {
    return {
      query: '',
      draftWord: {
        score: 1,
      },
      fields: [
        'id',
        'word',
        'score',
        'actions',
      ],
    };
  },
  apollo: {
    words: {
      query: ListRestrictedWords,
    },
  },
  computed: {
    displayWords() {
      return this.words && this.words.filter((word) => word.word.includes(this.query));
    },
  },
  methods: {
    async showModal() {
      this.$refs.BlockedWordModal.show();
    },
    async createWord() {
      await this.$apollo.mutate({
        mutation: InsertRestrictedWord,
        variables: {
          word: this.draftWord.word,
          score: this.draftWord.score,
        },
      });
      this.$apollo.queries.words.refetch();
      this.$bvToast.toast(`${this.draftWord.word} is now blocked`, {
        title: 'Blocked Words',
      });
      this.draftWord = {
        score: 1,
      };
    },
    async deleteWord(item) {
      await this.$apollo.mutate({
        mutation: DeleteRestrictedWord,
        variables: {
          id: item.id,
        },
      });
      this.$apollo.queries.words.refetch();
      this.$bvToast.toast('Word unblocked', {
        title: 'Blocked Words',
      });
    },
    async updateScore($event, item) {
      await this.$apollo.mutate({
        mutation: UpdateRestrictedWord,
        variables: {
          id: item.id,
          score: $event,
        },
      });
    },
  },
};
</script>
