<template>
  <div>
    <b-card header="RSS Feeds">
      <div class="feed" v-for="feed in feeds" :key="feed.id" @click="removeFeed(feed)">
        <div>{{ feed.feed_url }} </div>
        <div class="feed-category">{{ feed.category }}</div>
      </div>
      <b-button v-if="fullAccess" variant="outline-primary" size="small" block @click="showFeedModal()">Add Feed</b-button>
    </b-card>
    <b-modal ref="FeedModal" @ok="addFeed()">
      <b-form-group label="First Name">
        <b-form-input v-model="draftFeed.feedUrl" placeholder="Feed URL"></b-form-input>
      </b-form-group>
      <b-form-group label="Category">
        <b-form-select v-model="draftFeed.category" :options="categoryOptions" />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import AddRssFeed from '@/gql/users/AddRssFeed.gql';
import RemoveRssFeed from '@/gql/users/RemoveRssFeed.gql';
import ListRssFeeds from '@/gql/users/ListRssFeeds.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      draftFeed: {},
      categoryOptions: [
        { text: 'Politics', value: 'US' },
        { text: 'Entertainment', value: 'Entertainment' },
        { text: 'Technology', value: 'Technology' },
        { text: 'Fashion', value: 'Fashion' },
        { text: 'Family', value: 'Parents' },
        { text: 'Health', value: 'Health' },
        { text: 'Sports', value: 'Sports' },
        { text: 'Business', value: 'Business' },
        { text: 'Finance', value: 'Finance' },
        { text: 'World', value: 'Global' },
      ],
    };
  },
  apollo: {
    feeds: {
      query: ListRssFeeds,
      variables() {
        return {
          userId: this.user.id,
        };
      },
    },
  },
  computed: {
    fullAccess() {
      return $auth.accessType() === 'full';
    },
  },
  methods: {
    showFeedModal() {
      this.draftFeed = {
        userId: this.user.id,
      };
      this.$refs.FeedModal.show();
    },
    async addFeed() {
      await this.$apollo.mutate({
        mutation: AddRssFeed,
        variables: this.draftFeed,
      });
      // this.$emit('refresh');
      this.$apollo.queries.feeds.refetch();
    },
    async removeFeed(feed) {
      if (confirm('Are you sure you wish to remove this feed?')) {
        await this.$apollo.mutate({
          mutation: RemoveRssFeed,
          variables: {
            id: feed.id,
          },
        });
        // this.$emit('refresh');
        this.$apollo.queries.feeds.refetch();
      }
    },
  },
};
</script>
<style scoped>
.feed
{
  border-bottom: solid 1px #efefef;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.feed-category
{
  font-size: 12px;
  margin-top: 5px;
}
</style>
