<template>
  <b-container>
    <app-page-header title="Blocked Domains">
      <b-form>
        <b-input v-model="query" placeholder="Search Blocked Domains"></b-input>
      </b-form>
      <b-button @click="createUrl()" variant="primary">+ Block New Domain</b-button>
    </app-page-header>
    <b-spinner v-if="!urls" />
    <b-table :items="displayDomains" :fields="fields" v-if="urls">
      <template #cell(actions)="data">
        <b-button @click="deleteUrl(data.item)" size="small" variant="danger">Unblock</b-button>
      </template>
    </b-table>
  </b-container>
</template>
<script>
import ListRestrictedUrls from '@/gql/restrictedUrls/ListRestrictedUrls.gql';
import InsertRestrictedUrl from '@/gql/restrictedUrls/InsertRestrictedUrl.gql';
import DeleteRestrictedUrl from '@/gql/restrictedUrls/DeleteRestrictedUrl.gql';
import AppPageHeader from '@/components/PageHeader.vue';

export default {
  components: {
    AppPageHeader,
  },
  data() {
    return {
      query: '',
      fields: [
        'id',
        'host',
        'actions',
      ],
    };
  },
  apollo: {
    urls: {
      query: ListRestrictedUrls,
    },
  },
  computed: {
    displayDomains() {
      return this.urls && this.urls.filter((url) => url.host.includes(this.query));
    },
  },
  methods: {
    async createUrl() {
      const url = prompt('What URL?');
      if (url) {
        // const host = getHost(url);
        const host = url.replace('http://', '').replace('https://', '');
        await this.$apollo.mutate({
          mutation: InsertRestrictedUrl,
          variables: {
            host,
          },
        });
        this.$bvToast.toast(`${host} is now blocked`, {
          title: 'Blocked Domains',
        });
        this.$apollo.queries.urls.refetch();
      }
    },
    async deleteUrl(item) {
      await this.$apollo.mutate({
        mutation: DeleteRestrictedUrl,
        variables: {
          id: item.id,
        },
      });
      this.$bvToast.toast('Domain unblocked', {
        title: 'Blocked Domains',
      });
      this.$apollo.queries.urls.refetch();
    },
  },
};
</script>
