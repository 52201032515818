<template>
  <div class="full-content">
    <b-spinner v-if="!report"></b-spinner>
    <div class="control-bar" v-if="report">
      <span>User Reports: </span>
      <span>({{report.total_reports}} complaints | Last reported {{report.latest_report | moment('from')}})</span>
      <span class="flex-1"></span>
      <b-button variant="info" @click="suspendUser()">Suspend User</b-button>
      <!-- <b-button variant="danger" @click="deleteUser()">Delete User</b-button> -->
      <b-button variant="success" @click="clearUserReports()">Clear Reports</b-button>
    </div>
    <div class="flex-1">
      <iframe :src="reportSrc"></iframe>
    </div>
  </div>
</template>
<script>
import ListUserReports from '@/gql/reports/ListUserReports.gql';
import DeleteUser from '@/gql/users/DeleteUser.gql';
import SuspendUser from '@/gql/suspendeds/SuspendUser.gql';
import ClearUserReports from '@/gql/reports/ClearUserReports.gql';

export default {
  data() {
    return {
      report: null,
      reports: [],
    };
  },
  computed: {
    reportSrc() {
      return this.report && `${process.env.VUE_APP_URL}/#/users/id/${this.report.id}`;
    },
  },
  methods: {
    async next() {
      if (!this.reports.length) {
        const resp = await this.$apollo.query({
          query: ListUserReports,
        });
        this.reports = [...resp.data.reports];
        console.log(this.reports);
      }
      this.report = this.reports.pop();
    },
    async clearUserReports() {
      await this.$apollo.mutate({
        mutation: ClearUserReports,
        variables: {
          id: this.report.id,
        },
      });
      this.next();
    },
    async deleteUser() {
      if (confirm('Are you sure you wish to delete this user? This cannot be undone.')) {
        await this.$apollo.mutate({
          mutation: DeleteUser,
          variables: {
            id: this.report.id,
          },
        });
        this.clearUserReports();
      }
    },
    async suspendUser() {
      if (confirm('Are you sure you wish to suspend this user?')) {
        await this.$apollo.mutate({
          mutation: SuspendUser,
          variables: {
            id: this.report.id,
          },
        });
        this.clearUserReports();
      }
    },
  },
  mounted() {
    this.next();
  },
};
</script>
<style scoped>
.control-bar
{
  background-color: #555;
  color: white;
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
}
</style>
