<template>
  <b-card header="Search Ranking" class="mb-3">
    <table class="table table-sm table-borderless">
      <tr>
        <td>Connections Priority</td>
        <td class="text-right">
          {{draftParams.priorityScore || 'Not Set'}}
        </td>
      </tr>
      <tr>
        <td>Channels Priority</td>
        <td class="text-right">
          {{draftParams.channelPriorityScore || 'Not Set'}}
        </td>
      </tr>
    </table>
    <b-button v-if="fullAccess" variant="outline-primary" size="small" block @click="showScoreModal()">Edit Scores</b-button>
    <b-modal ref="ScoreModal" @ok="saveScores()">
      <b-form-group label="Connections Priority">
        <b-form-select v-model="draftParams.priorityScore" :options="options"></b-form-select>
      </b-form-group>
      <b-form-group label="Channels Priority">
        <b-form-select v-model="draftParams.channelPriorityScore" :options="options"></b-form-select>
      </b-form-group>
    </b-modal>
  </b-card>
</template>
<script>
import $auth from '@/services/auth';
import SetUserScores from '@/gql/users/SetUserScores.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullAccess() {
      return $auth.accessType() === 'full';
    },
  },
  data() {
    return {
      draftParams: {
        priorityScore: this.user.priorityScore,
        channelPriorityScore: this.user.channelPriorityScore,
      },
      options: [
        { value: 10, text: 'Very low visibility (10)' },
        { value: 30, text: 'Low visibility (30)' },
        { value: 50, text: 'Medium visibility (50)' },
        { value: 70, text: 'High visibility (70)' },
        { value: 90, text: 'Very high visibility (90)' },
      ],
    };
  },
  methods: {
    showScoreModal() {
      this.$refs.ScoreModal.show();
    },
    async saveScores() {
      console.log('Save Scores', this.user);
      this.$apollo.mutate({
        mutation: SetUserScores,
        variables: {
          userId: this.user.id,
          priorityScore: this.draftParams.priorityScore,
          channelPriorityScore: this.draftParams.channelPriorityScore,
        },
      });
    },
  },
};
</script>
