<template>
  <b-card header="Password" class="mb-3">
    <b-button variant="outline-primary" size="small" block @click="resetPassword()">Reset Password</b-button>
  </b-card>
</template>
<script>
import $auth from '@/services/auth';
import ResetPassword from '@/gql/users/ResetPassword.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullAccess() {
      return $auth.accessType() === 'full';
    },
  },
  methods: {
    async resetPassword() {
      const password = prompt('What password?');
      if (password) {
        await this.$apollo.mutate({
          mutation: ResetPassword,
          variables: {
            userId: this.user.id,
            password: password.trim(),
          },
        });
        alert('Password set OK');
      }
    },
  },
};
</script>
