<template>
  <b-container>
    <app-page-header title="Moderation Queue">
      <b-button @click="flushHiveQueue()" variant="primary">Allow All</b-button>
    </app-page-header>
    <b-table :items="posts" :fields="fields" show-empty :busy="$apollo.queries.posts.loading">
      <template #table-busy>
        <b-spinner />
      </template>
      <template #empty>No posts left to review</template>
      <template #cell(post)="data">
        <div v-if="data.item.postedByUser" @click="viewUser(data.item.postedByUser)">{{data.item.postedByUser.username}}</div>
        <div v-if="!data.item.postedByUser">**Deleted Account**</div>
        <div>{{data.item.createdAt | moment('from')}}</div>
        <div>{{data.item.bodyText}}</div>
        <div v-if="data.item.images" class="image-holder">
          <div class="preview-img" v-for="img in data.item.images" :key="img.url" @click="zoom(img.url)">
            <img :src="cdn(img.url)" />
          </div>
        </div>
        <div v-if="data.item.video" class="video-holder">
          <app-video :src="data.item.video.url" />
        </div>
      </template>
      <template #cell(actions)="data">
        <b-button block variant="success" @click="approve(data.item)">Approve</b-button>
        <b-button block variant="danger" @click="reject(data.item)">Reject</b-button>
        <b-button block variant="info" @click="preview(data.item)">Preview</b-button>
      </template>
    </b-table>
    <b-modal ref="rejectionModal" ok-only @ok="doReject()" title="Reject Post">
      <div>Please provide a reason for this rejection:</div>
      <b-select v-model="rejectionReason">
        <b-select-option value="SexualContent">Sexual Content</b-select-option>
        <b-select-option value="Doxing">Doxing</b-select-option>
        <b-select-option value="Violence">Violence</b-select-option>
        <b-select-option value="Racial">Derogatory Racial</b-select-option>
        <b-select-option value="Other">Other</b-select-option>
      </b-select>
    </b-modal>
    <b-modal ref="imgPreviewModal">
      <img :src="previewImage" class="preview-image" />
    </b-modal>
  </b-container>
</template>
<script>
import ListModerationPosts from '@/gql/moderation/ListModerationPosts.gql';
import SetModerationStatus from '@/gql/moderation/SetModerationStatus.gql';
import GraphqlHiveFlushQueue from '@/gql/moderation/GraphqlHiveFlushQueue.gql';
import AppPageHeader from '@/components/PageHeader.vue';
import AppVideo from '@/components/Video.vue';

export default {
  components: {
    AppPageHeader,
    AppVideo,
  },
  data() {
    return {
      fields: [
        'post',
        'actions',
      ],
      previewImage: null,
      currentItem: null,
      rejectionReason: null,
    };
  },
  computed: {
    reportSrc() {
      return this.post && `${process.env.VUE_APP_URL}/#/posts/${this.post.id}`;
    },
  },
  apollo: {
    posts: {
      query: ListModerationPosts,
    },
  },
  methods: {
    cdn(url) {
      if (url.startsWith('us-west')) {
        return `https://chforum.backendcdn.com/${url}`;
      }
      if (url.includes('forum-clouthub.s3.us-west-1.amazonaws.com')) {
        return url.replace('forum-clouthub.s3.us-west-1.amazonaws.com', 'chforum.backendcdn.com');
      }
      if (url.includes('profile-clouthub.s3.us-west-1.amazonaws.com')) {
        return url.replace('profile-clouthub.s3.us-west-1.amazonaws.com', 'chprofile.backendcdn.com');
      }
      if (url.includes('d3029vgllsqvv6.cloudfront.net')) {
        return url.split('?')[0].replace('d3029vgllsqvv6.cloudfront.net', 'chforum.backendcdn.com');
      }
      return url;
    },
    async setStatus(post, status, reason) {
      await this.$apollo.mutate({
        mutation: SetModerationStatus,
        variables: {
          id: post.id,
          status,
          reason,
        },
      });
      this.$apollo.queries.posts.refetch();
    },
    approve(item) {
      this.setStatus(item, 'completed', null);
    },
    reject(item) {
      this.currentItem = item;
      this.$refs.rejectionModal.show();
    },
    doReject() {
      this.setStatus(this.currentItem, 'rejected', this.rejectionReason);
    },
    preview(post) {
      window.open(`${process.env.VUE_APP_URL}/#/posts/${post.id}`);
    },
    zoom(url) {
      this.previewImage = this.cdn(url);
      this.$refs.imgPreviewModal.show();
    },
    viewUser(user) {
      console.log('View User', user);
      this.$router.push({
        name: 'UserDetail',
        params: user,
      });
    },
    async flushHiveQueue() {
      // const url = prompt('What URL?');
      await this.$apollo.mutate({
        mutation: GraphqlHiveFlushQueue,
      });
      this.$bvToast.toast('HIVE queue now approved', {
        title: 'HIVE Approve All',
      });
      this.$apollo.queries.posts.refetch();
    },
  },
};
</script>
<style scoped>
.preview-img
{
  display: inline-block;
  width: 200px;
  height: 200px;
}
.preview-img img
{
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.preview-image
{
  max-width: 100%;
  max-height: 400px;
}
.video-holder
{
  width: 300px;
}
.image-holder
{
  max-width: 500px;
}
</style>
