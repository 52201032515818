<template>
  <b-container>
    <app-page-header title="Meeting Earnings"></app-page-header>
    <b-row>
      <b-col>
        <b-form-group>
          <label for="start">Report Start</label>
          <b-form-datepicker id="start" v-model="startDate" class="mb-2"></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label for="end">Report End</label>
          <b-form-datepicker id="end" v-model="endDate" class="mb-2"></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="metric">
          <div class="metric-value">${{ totalEarnings }}</div>
          <div class="metric-name">Total Earnings</div>
        </div>
      </b-col>
      <b-col>
        <div class="metric">
          <div class="metric-value">{{ tickets && tickets.length || 0 }}</div>
          <div class="metric-name">Tickets Sold</div>
        </div>
      </b-col>
      <b-col>
        <div class="metric">
          <div class="metric-value">{{ hostCount }}</div>
          <div class="metric-name">Unique Hosts</div>
        </div>
      </b-col>
      <b-col>
        <div class="metric">
          <div class="metric-value">{{ eventCount }}</div>
          <div class="metric-name">Paid Meetings</div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-table :sort-desc="sortStartTime" sort-by="startTime" :items="meetings" striped class="mt-5" :fields="meetingsFields">
        <template #head(startTime)="data">
          <div @click="sortStartTime = !sortStartTime">
            {{ data.label }}
          </div>
        </template>
        <template #cell(earnings)="data">
          ${{ data.item.earnings }}
        </template>
        <template #cell(hostProfile)="data">
          <b-button v-router-link="{name:'UserDetail', params:{id: data.item.hostId}}">Host Profile</b-button>
        </template>
      </b-table>
    </b-row>
  </b-container>
</template>
<script>
import moment from 'moment';
import AppPageHeader from '@/components/PageHeader.vue';
import ListPaidTickets from '@/gql/meetings/ListPaidTickets.gql';

export default {
  components: {
    AppPageHeader,
  },
  data() {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    return {
      sortStartTime: true,
      startDate: oneMonthAgo,
      endDate: (new Date()),
      meetingsFields: [
        'meetingName',
        'hostName',
        'ticketsSold',
        'earnings',
        {
          key: 'startTime',
          sortable: true,
        },
        'hostProfile',
      ],
    };
  },
  apollo: {
    tickets: {
      query: ListPaidTickets,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
    },
  },
  computed: {
    totalEarnings() {
      let earnings = 0;
      if (this.tickets) {
        this.tickets.forEach((ticket) => {
          earnings = this.sum(ticket.ticketPricePaid, earnings);
        });
      }
      return earnings;
    },
    meetings() {
      if (!this.tickets) return [];

      return Object.values(this.tickets.reduce((uniqueMeetings, ticket) => {
        const existedMeeting = uniqueMeetings[ticket.meetingId];

        if (existedMeeting) {
          existedMeeting.ticketsSold += 1;
          existedMeeting.earnings = this.sum(ticket.ticketPricePaid, existedMeeting.earnings);
        } else {
          uniqueMeetings[ticket.meetingId] = {
            meetingName: ticket.meeting.name,
            hostId: ticket.meeting.host.id,
            hostName: ticket.meeting.host.username,
            ticketsSold: 1,
            startTime: moment(ticket.meeting.startTime).format('MM/DD/YYYY hh:mm'),
            earnings: ticket.ticketPricePaid || '0.00',
          };
        }

        return uniqueMeetings;
      }, {}));
    },
    hostCount() {
      if (!this.tickets) return 0;
      return new Set(this.tickets.map((ticket) => ticket.meeting.host.id)).size;
    },
    eventCount() {
      const meetingMap = {};
      if (this.tickets) {
        this.tickets.forEach((ticket) => {
          meetingMap[ticket.meetingId] = 1;
        });
      }
      return Object.keys(meetingMap).length;
    },
  },
  methods: {
    sum(a, b) {
      return (Number(a) + Number(b)).toFixed(2);
    },
  },
};
</script>
<style scoped>
.metric {
  border: solid 1px #888;
  padding: 10px;
  text-align: center;
}
.metric-value {
  font-size: 30px;
  margin-bottom: 10px;
}
.metric-name  {
  font-weight:bold;
}
</style>
