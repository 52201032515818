import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';

const user = {};
let userId = null;

const getUser = async () => user;

const setHeader = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const isLoggedIn = async () => {
  if (!localStorage.access_token) return false;
  const userdata = await getUser();
  return !!userdata;
};

const logout = () => {
  localStorage.removeItem('access_token');
  window.location.href = '/';
};

const getUserId = () => {
  if (!userId && localStorage.access_token) {
    const payload = jsonwebtoken.decode(localStorage.access_token);
    userId = payload.id;
  }
  return userId;
};

function accessType() {
  const accessMap = {
    'ebcdf8b5-ede5-4737-b6c9-f9a857fee5d3': 'full', // @jeff
    '3954ee24-5dd1-4b0e-b29a-42bdd750fbc8': 'full', // @clouthub
    'be03853c-6190-4c6f-bdf5-912253d9b90d': 'full', // @janakan
    'fe2c28fa-e9a9-46cf-82c0-321af7eac4f9': 'full', // @mattocks
    'ab5de447-399f-489a-b4c5-af936979635f': 'full', // @victordev
    'b824cdc2-a3e5-4d78-b0b3-f4ccf1194d53': 'full', // @ninjakreborn
    'fca8ad0c-4339-4c0b-904a-bcd235054d5f': 'full', // @fsiswanto
    '4fca4909-eff4-4815-b58a-a08a0823c322': 'full', // @PurkeGopinand
  };
  return accessMap[getUserId()];
}

function isAccessFull() {
  return accessType() === 'full';
}
function isSuper() {
  // return getUserId() === 'fe2c28fa-e9a9-46cf-82c0-321af7eac4f9';
  return true;
}

function checkAuth() {
  // Logout if expired
  setInterval(() => {
    if (localStorage.access_token) {
      try {
        const payload = jsonwebtoken.decode(localStorage.access_token);
        const expiry = (payload.exp * 1000);
        if (Date.now() > expiry) {
          logout();
        }
      } catch (e) {
        logout();
      }
    }
  }, 1000);
}
checkAuth();

export default {
  isLoggedIn,
  setHeader,
  getUser,
  logout,
  getUserId,
  accessType,
  isAccessFull,
  isSuper,
};
