<template>
  <b-button variant="danger" @click="deleteUser()">
    Delete
  </b-button>
</template>

<script>
import DeleteUser from '@/gql/users/DeleteUser.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async deleteUser() {
      if (confirm('Are you sure you wish to delete this user? This cannot be undone.')) {
        await this.$apollo.mutate({
          mutation: DeleteUser,
          variables: {
            id: this.user.id,
          },
        });
        this.$router.push({
          name: 'UserList',
        });
      }
    },
  },
};
</script>
