<template>
    <div>
        <b-container>
            <b-spinner v-if="!featuredPost"></b-spinner>
            <div v-if="featuredPost">
                <app-page-header :title="displayName">
                    <template #subtitle>
                        To be displayed at Position - {{ featuredPost.position }} in the
                        feed
                    </template>
                </app-page-header>
            </div>

            <div v-if="featuredPost">
                <b-row>
                    <b-col>
                        <b-card header="Feature Slot">
                            <b-row>
                                <b-col>
                                    <table class="table table-sm table-borderless">
                                        <tr>
                                            <td>Slot</td>
                                            <td>
                                                {{ featuredPost.slot }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Position</td>
                                            <td>
                                                {{ featuredPost.position }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Display Start</td>
                                            <td>
                                                {{ featuredPost.display_start }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Display End</td>
                                            <td>
                                                {{ featuredPost.display_end }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Slot Id</td>
                                            <td>
                                                {{ featuredPost.id }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Post Id</td>
                                            <td>
                                                {{ featuredPost.postid }}
                                            </td>
                                        </tr>
                                    </table>
                                </b-col>
                                <b-col>
                                    <div class="text-center">
                                        <b-icon icon="check-circle" class="account-status-icon"
                                            v-if="featuredPost.status"></b-icon>
                                        <b-icon icon="shield-exclamation" class="account-status-icon suspended"
                                            v-if="!featuredPost.status"></b-icon>
                                        <div v-if="featuredPost.status">Feature Slot Active</div>
                                        <div v-if="!featuredPost.status">Feature Slot InActive</div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-card header="Post Info">
                            <table class="table table-sm table-borderless">
                                <tr>
                                    <td>Id</td>
                                    <td>
                                        {{ featuredPost.post.id }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Root Post Id</td>
                                    <td>
                                        {{ featuredPost.post.rootPostId }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Parent Post Id</td>
                                    <td>
                                        {{ featuredPost.post.parentPostId }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Slug</td>
                                    <td>
                                        {{ featuredPost.post.slug }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Body Text</td>
                                    <td>
                                        {{ featuredPost.post.bodyText }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Post Level</td>
                                    <td>
                                        {{ featuredPost.post.postLevel }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Type</td>
                                    <td>
                                        {{ featuredPost.post.type }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Category</td>
                                    <td>
                                        {{ featuredPost.post.category }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tags</td>
                                    <td>
                                        {{ featuredPost.post.tags }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Profanity Status</td>
                                    <td>
                                        {{ featuredPost.post.profanityStatus }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Created At</td>
                                    <td>
                                        {{ featuredPost.post.createdAt }}
                                    </td>
                                </tr>
                            </table>
                        </b-card>
                    </b-col>
                    <b-col>
                        <b-row>
                            <b-col>
                                <b-card header="Post Statistics" class="mb-3">
                                    <table class="table table-sm table-borderless">
                                        <tr>
                                            <td>Views Count</td>
                                            <td class="text-right">
                                                {{ featuredPost.post.viewCount }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Shares Count</td>
                                            <td class="text-right">
                                                {{ featuredPost.post.sharedPostCount }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Comments Count</td>
                                            <td class="text-right">
                                                {{ featuredPost.post.commentsCount }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Reactions Count</td>
                                            <td class="text-right">
                                                {{ featuredPost.post.reactionCount }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Replies Count</td>
                                            <td class="text-right">
                                                {{ featuredPost.post.replyCount }}
                                            </td>
                                        </tr>
                                    </table>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-card header="Featured Post Statistics" class="mb-3">
                                    <table class="table table-sm table-borderless">
                                        <tr>
                                            <td>Feed impressions count</td>
                                            <td class="text-right">
                                                {{ featuredPost.featuredPostAnalytics.feed_impressionscount }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Feed clicks count</td>
                                            <td class="text-right">
                                                {{ featuredPost.featuredPostAnalytics.feed_clickscount }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Feed views count</td>
                                            <td class="text-right">
                                                {{ featuredPost.featuredPostAnalytics.feed_viewscount }}
                                            </td>
                                        </tr>
                                        <tr v-if="featuredPost.post.type === 'video'">
                                            <td>Channels impressions count</td>
                                            <td class="text-right">
                                                {{ featuredPost.featuredPostAnalytics.channels_impressionscount }}
                                            </td>
                                        </tr>
                                        <tr v-if="featuredPost.post.type === 'video'">
                                            <td>Channels clicks count</td>
                                            <td class="text-right">
                                                {{ featuredPost.featuredPostAnalytics.channels_clickscount }}
                                            </td>
                                        </tr>
                                        <tr v-if="featuredPost.post.type === 'video'">
                                            <td>Channels views count</td>
                                            <td class="text-right">
                                                {{ featuredPost.featuredPostAnalytics.channels_viewscount }}
                                            </td>
                                        </tr>
                                        <!-- <tr>
                                            <td>Shares Count</td>
                                            <td class="text-right">
                                                {{ featuredPost.post.sharedPostCount }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Comments Count</td>
                                            <td class="text-right">
                                                {{ featuredPost.post.commentsCount }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Reactions Count</td>
                                            <td class="text-right">
                                                {{ featuredPost.post.reactionCount }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Replies Count</td>
                                            <td class="text-right">
                                                {{ featuredPost.post.replyCount }}
                                            </td>
                                        </tr> -->
                                    </table>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </b-container>
    </div>
</template>
<script>
import $auth from '@/services/auth';
import GetFeaturedPost from '@/gql/channels/GetFeaturedPost.gql';
import AppPageHeader from '@/components/PageHeader.vue';

export default {
  components: {
    AppPageHeader,
  },
  props: {
    postid: {
      type: String,
      required: true,
    },
  },
  apollo: {
    featuredPost: {
      query: GetFeaturedPost,
      variables() {
        return {
          id: this.postid,
        };
      },
    },
  },
  computed: {
    superAccess() {
      return $auth.isSuper();
    },
    fullAccess() {
      return $auth.accessType() === 'full';
    },
    displayName() {
      return (
        this.featuredPost && `Featured Post Slot - ${this.featuredPost.slot}`
      );
    },
  },
  methods: {
    refresh() {
      // this.$apollo.queries.user.refetch();
      this.$apollo.queries.featuredPost.refetch();
    },
  },
};
</script>

<style>
.account-status-icon {
  width: 50px;
  height: 50px;
  color: green;
}
.account-status-icon.suspended {
  color: red;
}
</style>
