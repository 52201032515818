<template>
  <b-card :header="header">
    <b-spinner v-if="!metrics" />
    <table class="table table-sm table-borderless">
      <tr v-for="metric in metrics" :key="metric.name">
        <td>{{metric.status}}</td>
        <td class="text-right">
          {{metric.count}}
        </td>
      </tr>
    </table>
  </b-card>
</template>
<script>
import GetTranscodingSummary from '@/gql/channels/GetTranscodingSummary.gql';

export default {
  props: {
    days: {
      type: Number,
      required: true,
    },
  },
  computed: {
    header() {
      return `${this.days} day report`;
    },
  },
  apollo: {
    metrics: {
      query: GetTranscodingSummary,
      variables() {
        return {
          days: this.days,
        };
      },
    },
  },
};
</script>
