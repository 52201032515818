<template>
  <b-container>
    <app-page-header title="Allowed Email Domains">
      <b-form>
        <b-input v-model="query" placeholder="Search Allowed Domains"></b-input>
      </b-form>
      <b-button @click="createUrl()" variant="primary">+ Allow New Domain</b-button>
    </app-page-header>
    <b-spinner v-if="!urls" />
    <b-table :items="displayDomains" :fields="fields" v-if="urls">
      <template #cell(domain)="data">
        {{ data.item }}
      </template>
      <template #cell(actions)="data">
        <b-button @click="deleteUrl(data.item)" size="small" variant="danger">Delete</b-button>
      </template>
    </b-table>
  </b-container>
</template>
<script>
import ListAllowedEmailDomains from '@/gql/allowedEmails/ListAllowedEmailDomains.gql';
import InsertAllowedEmailDomain from '@/gql/allowedEmails/AddAllowedEmailDomain.gql';
import DeleteAllowedEmailDomain from '@/gql/allowedEmails/RemoveAllowedEmailDomain.gql';
import AppPageHeader from '@/components/PageHeader.vue';

export default {
  components: {
    AppPageHeader,
  },
  data() {
    return {
      query: '',
      fields: [
        'domain',
        'actions',
      ],
    };
  },
  apollo: {
    urls: {
      query: ListAllowedEmailDomains,
    },
  },
  computed: {
    displayDomains() {
      return this.urls && this.urls.filter((url) => url.includes(this.query));
    },
  },
  methods: {
    async createUrl() {
      const url = prompt('What Domain?');
      if (url) {
        // const host = getHost(url);
        const domain = url.replace('http://', '').replace('https://', '');
        await this.$apollo.mutate({
          mutation: InsertAllowedEmailDomain,
          variables: {
            domain,
          },
        });
        this.$bvToast.toast(`${domain} is now allowed`, {
          title: 'Allowed Email Domain',
        });
        this.$apollo.queries.urls.refetch();
      }
    },
    async deleteUrl(item) {
      await this.$apollo.mutate({
        mutation: DeleteAllowedEmailDomain,
        variables: {
          domain: item,
        },
      });
      this.$bvToast.toast('Domain deleted', {
        title: 'Allowed Email Domains',
      });
      this.$apollo.queries.urls.refetch();
    },
  },
};
</script>
