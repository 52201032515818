<template id="">
  <video
    ref="videoPlayer"
    data-setup='{"fluid": true}'
    class="video-js video-player vjs-default-skin vjs-big-play-centered"
  ></video>
</template>

<script>
import 'video.js/dist/video-js.css';
import videojs from 'video.js';

export default {
  props: {
    src: {
      type: String,
      required: false,
    },
    poster: {
      type: String,
      required: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    src() {
      this.load();
    },
  },
  methods: {
    play(src, type) {
      const options = {
        poster: this.poster,
        autoplay: this.autoplay,
        controls: true,
        sources: [
          {
            src,
            type,
          },
        ],
      };
      this.player = videojs(this.$refs.videoPlayer, options, () => {
        if (this.autoplay) {
          this.player.play();
        }
      });
    },
    async load() {
      if (!this.src) {
        return false;
      }
      if (this.src.includes('iframe.dacast.com')) {
        // DaCast VOD
        const p1 = this.src.split('/')[4];
        const p2 = this.src.split('/')[6];
        const hlsInfo = `https://playback.dacast.com/content/access?contentId=${p1}_f_${p2}&provider=dacast`;
        const streamInfo = await fetch(hlsInfo).then((r) => r.json());
        const hlsSrc = streamInfo.hls;
        this.play(hlsSrc);
      } else if (this.src.includes('playback.dacast.com')) {
        const streamInfo = await fetch(this.src).then((r) => r.json());
        const hlsSrc = streamInfo.hls;
        console.log(hlsSrc);
        this.play(hlsSrc);
      } else if (this.src.includes('.m3u8')) {
        this.play(this.src, 'application/x-mpegURL');
      } else {
        if (this.src.includes('.m3u8')) {
          this.play(this.src, 'application/x-mpegURL');
        }
        let { src } = this;
        // Temporarily disabling our CDN
        if (!this.src.includes('http')) {
          src = `https://forum-clouthub.s3.amazonaws.com/${this.src}`;
        }
        // src = src.replace('chforum.backendcdn.com', 'forum-clouthub.s3.amazonaws.com');
        src = src.replace('forum-clouthub.s3.amazonaws.com', 'chforum.backendcdn.com');
        this.play(src, 'video/mp4');
      }
      return true;
    },
  },
  mounted() {
    this.load();
  },
};
</script>
<style media="screen">
.video-player {
  width: 100%;
  height: 100%;
  max-height: 400px;
  min-height: 300px;
  background-color: black;
}
</style>
